const themeGen = theme => ({  // eslint-disable-line
  footerBackgroundColor: '#e5ebef',
  footerForegroundColor: '#004a70',
  footerIconColor: 'secondary-fg',
  conf: {
    showNewsletterSection: true,
  },
  defaultOpenFromStart: true,
});

export default themeGen;
